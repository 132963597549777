<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="货主名称"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.name"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item label="货主简称"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.shortname"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item label="货主状态" prop="status">
            <el-select v-model="conditions.status" clearable multiple>
              <el-option
                v-for="(item, index) in enumStatus"
                :key="index"
                :value="index"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableList" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货主简称"
            prop="shortname"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货主"
            prop="name"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货主电话"
            prop="telephone"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="地址">
            <template v-slot="scope">
              {{
                scope.row.addrProvince +
                "/" +
                scope.row.addrCity +
                "/" +
                scope.row.addrCounty
              }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="详细地址"
            prop="addrDetail"
          ></el-table-column>
          <el-table-column label="审核状态">
            <template v-slot="scope">
              <el-tag size="small" :type="enumStatus[scope.row.status].type">{{
                enumStatus[scope.row.status].label
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="goDetail(scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import lodash from "lodash";
import { mapState } from "vuex";
export default {
  data() {
    return {
      enumStatus: {
        0: { label: "待审核", type: "warning" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      conditions: {
        page: 1,
        limit: 10,
        name: "",
        shortname: "",
        status: ["0"],
      },
      total: 0,
      tableList: [],
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    Pagination,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getPendingCount();
    });
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        this.transformConditions()
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    transformConditions() {
      let clone = lodash.cloneDeep(this.conditions);
      clone.status = clone.status.toString();
      return clone;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    async changeStatus(id, status) {
      const { data } = await this.$http(
        "api1",
        "/api/cargo/CargoOwner/update",
        "post",
        {
          id,
          status,
        }
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.$message.success("操作成功");
      this.getList();
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该货主, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/cargo/CargoOwner/delete",
            "post",
            {
              ids: [id],
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },

    goDetail(row) {
      this.$router.push({
        path: "/check/cargoOwnerDetails",
        query: { id: row.id },
      });
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
